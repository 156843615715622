<template>
    <uw-content title="工时统计">

        <!-- 表格 -->
        <uw-table :show-footer="false">

            <!-- 检索 -->
            <template slot="search">
                <users-select v-model="search.users_id" multiple @onChange="TableGet" />
                <el-date-picker v-model="search.year" clearable @change="DataChange" type="year" value-format="yyyy"  size="mini"></el-date-picker>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button @click="TableGet()" type="success" size="mini">刷新</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    height="100%"
                    show-footer
                    :data="users"
                    :loading="loading"
                    :footer-method="TableFooter"
                    :export-config="{}"
                    :print-config="{}"
                    @sort-change="TableSort">

                    <!-- 用户 -->
                    <vxe-column width="120" field="name" title="成员" fixed="left">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.avatar">{{ row.name[0] }}</el-avatar>
                                <el-link type="primary" @click="$refs.employeeDrawer.Open(row.id)">{{  row.name }}</el-link>
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 登记工时 -->
                    <vxe-column width="120" field="sum" title="登记工时/P" fixed="left" align="right" sortable>
                        <template #default="{ row }">
                            {{ row.sum }}
                        </template>
                    </vxe-column>

                    <vxe-column min-width="110" :field="item" align="right" v-for="item in Object.keys(month)" :key="item" :title="year + '年' + item + '月'" sortable>
                        <template #default="{ row }">
                            <el-popover v-if="row.month[item] != 0" placement="top-start" trigger="click">
                                <el-button size="mini" type="primary" @click="$refs.dialogFlow.Open({ user_id: row.id, year: year, month: item, stage: ['任务完成', '任务转交']  })">查看任务</el-button>
                                <el-button size="mini" type="primary" @click="$refs.dialogWo.Open({ user_id: row.id, year: year, month: item })">查看工单</el-button>
                                <el-link slot="reference" :underline="false">{{ row.month[item] }}</el-link>
                            </el-popover>
                            <el-link v-else :underline="false" type="info">-</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

        </uw-table>

        <!-- 组件 -->
        <pm-dialog-table-flow           ref="dialogFlow" />
        <pm-dialog-table-wo-day-log     ref="dialogWo"   />
        <hrm-employee-drawer            ref="employeeDrawer" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            
            users: [],
            sum: 0,
            month: [],
            year: null,

            // 检索
            search: {
                users_id: null,
                year: null,
                // team: true,
            },

            // 排序
            order: {
                method: 'asc',
                column: 'sum'
            },
        }
    },

    mounted () {
        this.TableGet()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 数据获取
        |--------------------------------------------------
        |
        */
        
        TableGet() {
            this.loading = true
            this.$http.post('/9api/pm/analysis/working-days-statistics', { order: this.order, search: this.search }).then((rps) => {
                this.users = rps.data.users
                this.sum = rps.data.sum
                this.month = rps.data.month
                this.year = rps.data.year
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 时间变更
        |--------------------------------------------------
        |
        */
        
        DataChange() {
            this.order.column = 'sum'
            this.TableGet()
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableGet()
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        TableFooter() {
            return [
                ['合计', this.sum, ...Object.values(this.month) ],
            ]
        },
    }
}
</script>